// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-contact-us-index-js": () => import("./../../../src/pages/contact-us/index.js" /* webpackChunkName: "component---src-pages-contact-us-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lp-de-chroma-spb-index-js": () => import("./../../../src/pages/lp/de-chroma-spb/index.js" /* webpackChunkName: "component---src-pages-lp-de-chroma-spb-index-js" */),
  "component---src-pages-lp-fr-chroma-spb-index-js": () => import("./../../../src/pages/lp/fr-chroma-spb/index.js" /* webpackChunkName: "component---src-pages-lp-fr-chroma-spb-index-js" */),
  "component---src-pages-lp-it-chroma-spb-index-js": () => import("./../../../src/pages/lp/it-chroma-spb/index.js" /* webpackChunkName: "component---src-pages-lp-it-chroma-spb-index-js" */),
  "component---src-pages-lp-spa-bm-a-2-index-js": () => import("./../../../src/pages/lp/spa-bm-a2/index.js" /* webpackChunkName: "component---src-pages-lp-spa-bm-a-2-index-js" */),
  "component---src-pages-lp-spa-chroma-index-js": () => import("./../../../src/pages/lp/spa-chroma/index.js" /* webpackChunkName: "component---src-pages-lp-spa-chroma-index-js" */),
  "component---src-pages-lp-spa-download-index-js": () => import("./../../../src/pages/lp/spa-download/index.js" /* webpackChunkName: "component---src-pages-lp-spa-download-index-js" */),
  "component---src-pages-lp-spb-bm-a-2-index-js": () => import("./../../../src/pages/lp/spb-bm-a2/index.js" /* webpackChunkName: "component---src-pages-lp-spb-bm-a-2-index-js" */),
  "component---src-pages-lp-spb-chroma-index-js": () => import("./../../../src/pages/lp/spb-chroma/index.js" /* webpackChunkName: "component---src-pages-lp-spb-chroma-index-js" */),
  "component---src-pages-lp-spb-download-index-js": () => import("./../../../src/pages/lp/spb-download/index.js" /* webpackChunkName: "component---src-pages-lp-spb-download-index-js" */),
  "component---src-pages-lp-spb-fl-2-a-2-chroma-index-js": () => import("./../../../src/pages/lp/spb-fl2a2-chroma/index.js" /* webpackChunkName: "component---src-pages-lp-spb-fl-2-a-2-chroma-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-terms-of-service-index-js": () => import("./../../../src/pages/terms-of-service/index.js" /* webpackChunkName: "component---src-pages-terms-of-service-index-js" */),
  "component---src-pages-ty-amo-thank-you-index-js": () => import("./../../../src/pages/ty/amo-thank-you/index.js" /* webpackChunkName: "component---src-pages-ty-amo-thank-you-index-js" */),
  "component---src-pages-ty-amo-thank-you-old-index-js": () => import("./../../../src/pages/ty/amo-thank-you-old/index.js" /* webpackChunkName: "component---src-pages-ty-amo-thank-you-old-index-js" */),
  "component---src-pages-ty-easyprint-clearbar-index-js": () => import("./../../../src/pages/ty/easyprint-clearbar/index.js" /* webpackChunkName: "component---src-pages-ty-easyprint-clearbar-index-js" */),
  "component---src-pages-ty-thank-you-index-js": () => import("./../../../src/pages/ty/thank-you/index.js" /* webpackChunkName: "component---src-pages-ty-thank-you-index-js" */),
  "component---src-pages-uninstall-index-js": () => import("./../../../src/pages/uninstall/index.js" /* webpackChunkName: "component---src-pages-uninstall-index-js" */),
  "component---src-pages-uninstalled-index-js": () => import("./../../../src/pages/uninstalled/index.js" /* webpackChunkName: "component---src-pages-uninstalled-index-js" */),
  "component---src-pages-unsubscribe-index-js": () => import("./../../../src/pages/unsubscribe/index.js" /* webpackChunkName: "component---src-pages-unsubscribe-index-js" */)
}

